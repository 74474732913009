var hasTouch = "ontouchstart" in document.documentElement, startEvent = hasTouch ? "touchstart" : "mousedown", moveEvent = hasTouch ? "touchmove" : "mousemove", endEvent = hasTouch ? "touchend" : "mouseup";
hasTouch && $.each("touchstart touchmove touchend".split(" "), function(e, t)
{
    jQuery.event.fixHooks[t] = jQuery.event.mouseHooks
}), jQuery.tableDnD = {
    currentTable         : null, dragObject : null, mouseOffset : null, oldY : 0, build : function(e)
    {
        return this.each(function()
        {
            this.tableDnDConfig = jQuery.extend({onDragStyle : null, onDropStyle : null, onDragClass : "tDnD_whileDrag", onDrop : null, onDragStart : null, scrollAmount : 5, serializeRegexp : /[^\-]*$/, serializeParamName : null, dragHandle : null}, e || {}), jQuery.tableDnD.makeDraggable(this)
        }), this
    }, makeDraggable     : function(e)
    {
        var t = e.tableDnDConfig;
        if (t.dragHandle) {
            var n = jQuery(e.tableDnDConfig.dragHandle, e);
            n.each(function()
            {
                jQuery(this).bind(startEvent, function(n)
                {
                    return jQuery.tableDnD.initialiseDrag(jQuery(this).parents("tr")[0], e, this, n, t), !1
                })
            })
        } else {
            var r = jQuery("tr", e);
            r.each(function()
            {
                var n = jQuery(this);
                n.hasClass("nodrag") || n.bind(startEvent, function(n)
                {
                    return "TD" == n.target.tagName ? (jQuery.tableDnD.initialiseDrag(this, e, this, n, t), !1) : void 0
                }).css("cursor", "move")
            })
        }
    }, initialiseDrag    : function(e, t, n, r, a)
    {
        jQuery.tableDnD.dragObject = e, jQuery.tableDnD.currentTable = t, jQuery.tableDnD.mouseOffset = jQuery.tableDnD.getMouseOffset(n, r), jQuery.tableDnD.originalOrder = jQuery.tableDnD.serialize(), jQuery(document).bind(moveEvent, jQuery.tableDnD.mousemove).bind(endEvent, jQuery.tableDnD.mouseup), a.onDragStart && a.onDragStart(t, n)
    }, updateTables      : function()
    {
        this.each(function()
        {
            this.tableDnDConfig && jQuery.tableDnD.makeDraggable(this)
        })
    }, mouseCoords       : function(e)
    {
        return e.pageX || e.pageY ? {x : e.pageX, y : e.pageY} : {x : e.clientX + document.body.scrollLeft - document.body.clientLeft, y : e.clientY + document.body.scrollTop - document.body.clientTop}
    }, getMouseOffset    : function(e, t)
    {
        t = t || window.event;
        var n = this.getPosition(e), r = this.mouseCoords(t);
        return {x : r.x - n.x, y : r.y - n.y}
    }, getPosition       : function(e)
    {
        var t = 0, n = 0;
        for (0 == e.offsetHeight && (e = e.firstChild); e.offsetParent;) {
            t += e.offsetLeft, n += e.offsetTop, e = e.offsetParent;
        }
        return t += e.offsetLeft, n += e.offsetTop, {x : t, y : n}
    }, mousemove         : function(e)
    {
        if (null != jQuery.tableDnD.dragObject) {
            "touchmove" == e.type && event.preventDefault();
            var t = jQuery(jQuery.tableDnD.dragObject), n = jQuery.tableDnD.currentTable.tableDnDConfig, r = jQuery.tableDnD.mouseCoords(e), a = r.y - jQuery.tableDnD.mouseOffset.y, o = window.pageYOffset;
            if (document.all && ("undefined" != typeof document.compatMode && "BackCompat" != document.compatMode ? o = document.documentElement.scrollTop : "undefined" != typeof document.body && (o = document.body.scrollTop)), r.y - o < n.scrollAmount) {
                window.scrollBy(0, -n.scrollAmount);
            } else {
                var l = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : document.body.clientHeight;
                l - (r.y - o) < n.scrollAmount && window.scrollBy(0, n.scrollAmount)
            }
            if (a != jQuery.tableDnD.oldY) {
                var u = a > jQuery.tableDnD.oldY;
                jQuery.tableDnD.oldY = a, n.onDragClass ? t.addClass(n.onDragClass) : t.css(n.onDragStyle);
                var i = jQuery.tableDnD.findDropTargetRow(t, a);
                i && (u && jQuery.tableDnD.dragObject != i ? jQuery.tableDnD.dragObject.parentNode.insertBefore(jQuery.tableDnD.dragObject, i.nextSibling) : u || jQuery.tableDnD.dragObject == i || jQuery.tableDnD.dragObject.parentNode.insertBefore(jQuery.tableDnD.dragObject, i))
            }
            return !1
        }
    }, findDropTargetRow : function(e, t)
    {
        for (var n = jQuery.tableDnD.currentTable.rows, r = 0; r < n.length; r++) {
            var a = n[r], o = this.getPosition(a).y, l = parseInt(a.offsetHeight) / 2;
            if (0 == a.offsetHeight && (o = this.getPosition(a.firstChild).y, l = parseInt(a.firstChild.offsetHeight) / 2), t > o - l && o + l > t) {
                if (a == e) {
                    return null;
                }
                var u = jQuery.tableDnD.currentTable.tableDnDConfig;
                if (u.onAllowDrop) {
                    return u.onAllowDrop(e, a) ? a : null;
                }
                var i = jQuery(a).hasClass("nodrop");
                return i ? null : a
            }
        }
        return null
    }, mouseup           : function()
    {
        if (jQuery.tableDnD.currentTable && jQuery.tableDnD.dragObject) {
            jQuery(document).unbind(moveEvent, jQuery.tableDnD.mousemove).unbind(endEvent, jQuery.tableDnD.mouseup);
            var e = jQuery.tableDnD.dragObject, t = jQuery.tableDnD.currentTable.tableDnDConfig;
            t.onDragClass ? jQuery(e).removeClass(t.onDragClass) : jQuery(e).css(t.onDropStyle), jQuery.tableDnD.dragObject = null;
            var n = jQuery.tableDnD.serialize();
            t.onDrop && jQuery.tableDnD.originalOrder != n && t.onDrop(jQuery.tableDnD.currentTable, e), jQuery.tableDnD.currentTable = null
        }
    }, serialize         : function()
    {
        return jQuery.tableDnD.currentTable ? jQuery.tableDnD.serializeTable(jQuery.tableDnD.currentTable) : "Error: No Table id set, you need to set an id on your table and every row"
    }, serializeTable    : function(e)
    {
        for (var t = "", n = e.id, r = e.rows, a = 0; a < r.length; a++) {
            t.length > 0 && (t += "&");
            var o = r[a].id;
            o && o && e.tableDnDConfig && e.tableDnDConfig.serializeRegexp && (o = o.match(e.tableDnDConfig.serializeRegexp)[0]), t += n + "[]=" + o
        }
        return t
    }, serializeTables   : function()
    {
        var e = "";
        return this.each(function()
        {
            e += jQuery.tableDnD.serializeTable(this)
        }), e
    }
}, jQuery.fn.extend({tableDnD : jQuery.tableDnD.build, tableDnDUpdate : jQuery.tableDnD.updateTables, tableDnDSerialize : jQuery.tableDnD.serializeTables});